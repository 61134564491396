












import { Component, Prop, Vue } from 'vue-property-decorator'
import { SearchTileDefinition } from '../../../SearchResults.contracts'
import CloseIcon from './CloseIcon.vue'

@Component<SearchResultTile>({
  name: 'SearchResultTile',
  components: {
    CloseIcon
  }
})
export default class SearchResultTile extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly removeAllTile!: boolean

  @Prop({ type: Object, required: true, default: null })
  public readonly tileItem!: SearchTileDefinition | null
}

